@import '/assets/scss/core/all';
@import url('https://fonts.googleapis.com/css?family=PT+Mono|Staatliches&display=swap');

.App {
  min-height: 100vh;
}

.App-header {
  color: $black;
  font-size: calc(10px + 2vmin);
  @include flexbox(column, wrap, flex-start, center);
}

h1,
h2,
h3 {
    font-family: 'Staatliches', cursive;
}

p,
a,
button,
input,
textarea,
div {
    font-family: 'PT Mono', monospace;
}
