@import url(https://fonts.googleapis.com/css?family=PT+Mono|Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* RESETS */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
Taylor Tobin's Reset -- covers resets not included in Meyer Reset
*/
/* $box-sizing - https://www.paulirish.com/2012/box-sizing-border-box-ftw/
    apply a natural box layout model to all elements, but allowing components to change
*/
html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

html,
body {
  margin: 0;
  padding: 0;
  cursor: default; }

#app {
  width: 100%;
  overflow: hidden; }
  #app[v-cloak] {
    display: none; }

a {
  display: block;
  line-height: normal;
  text-decoration: none;
  color: inherit; }

p a, h1 a, h2 a, h3 a, h4 a {
  display: inline;
  display: initial; }

article p a {
  display: inline;
  display: initial;
  text-decoration: none;
  text-decoration: initial;
  color: #000;
  color: initial; }

img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

svg * {
  fill: inherit; }

figure svg {
  display: block;
  width: 100%;
  height: 100%; }

em {
  font-style: italic; }

strong {
  font-weight: 700; }

input {
  border: 1px solid #f4f4f4; }

input:focus {
  outline: none; }

* {
  -webkit-tap-highlight-color: transparent;
  /* for remove highlight */ }

a,
label,
button,
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="checkbox"] {
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"][readonly] {
  pointer-events: none; }

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  display: block;
  width: 100%;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }
  input[type="submit"]::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus,
  button:focus {
    outline: 0; }

/** ACCESSIBILITY **/
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px); }

/* PROJECT SPECIFIC */
/** COLORS **/
/** LAYOUT **/
/** FONT MIXINS **/
/** TYPE PATTERNS **/
/** BREAKPOINTS **/
/** BREAKPOINT MIXIN **/
/** CUSTOM BREAKPOINTS **/
/* USAGE
.thing {
  color: red;
  @include bps(870) { // magic number $shame
    color: blue;
  }
}
*/
/** LAYOUT MIXINS **/
/** Positioning: z-index **/
/** USAGE
 ** reference: https://www.sitepoint.com/better-solution-managing-z-index-sass/
   z-index: z('top');
   or
   z-index: z('modal', 'content');
**/
/** BUTTONS DEFAULTS **/
.App {
  min-height: 100vh; }

.App-header {
  color: #353238;
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }

h1,
h2,
h3 {
  font-family: 'Staatliches', cursive; }

p,
a,
button,
input,
textarea,
div {
  font-family: 'PT Mono', monospace; }

